.ds {
  background-color: black;
  height: 50px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  .right-block {
    display: flex;

  }

  .ds-logo {
    font-size: large;
    color: greenyellow;
  }
  .ds-dropdown {
    display: flex;
    
  }
  .lang-block 
  {
    position: absolute;
    top: 100px;
  }
  .ds-menu {
    display: flex;
    margin-left: 10px;
    align-items: center;
    justify-content: space-between;
      .menu {
        padding-left: 10px;
      }
  }
}