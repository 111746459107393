.ui-dropdown-box { 
  position: relative;
  .ui-dropdown {
    min-width: 100px;
    background-color: gray;
    color: black;
    position: absolute;
      .dropdown-menu {
        display: block;
      }
  }
  .ui-dropdown-trigger {
    
  }
}