.ds-footer {
  background-color: blue;
  color: palegreen;
  align-items: center;
  display: flex;
  justify-content: end;
  height: 50px;
  position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	
}