.column {
  float: left;
  width: 50%;
  padding: 10px;
  background-color: gray;
}
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
}
