.ui-dropdown-select {
 
  .elements {
    display: flex;
    flex-direction: column;
  }

  .element {
    display: block;
    padding: 10px;
    width: 100%;
    
    &.active {
      background-color: gray;
    }
  }

}