.block1 {
  margin: 50px;
  height: 300px;
  width: 300px;
  border: solid black 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  .block2, .block3 {
    height: 75px;
    width: 75px;
    margin: 10px;
    border: solid black 1px;
    background-color: gray;
      &.green {
        color: green;
      }
      &.red {
        color: red;
      }
      &.yellow {
        color: yellow;
      }
  }
}
